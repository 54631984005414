import { useEffect, useState } from "react";
import { Button, Switch, Tooltip, Typography } from "@mui/material";
import AutomatedRemediationDialog from "./AutomatedRemediationDialog";
import AlertDialog from "./AlertDialog";
import useSnackBars from "../../hooks/useSnackbar";
import Control from "../../models/Control";
import { Row } from "@tanstack/react-table";
import useAccount from "../../hooks/useAccount";
import MultiInputControls from "../../models/MultiInputControls";
import MultiInputCloudwatchRemediateDialog from "./multi-input-remediation-dialogs/MultiInputCloudwatchRemediateDialog";
import HandymanIcon from "@mui/icons-material/Handyman";

const AutomateSwitch = ({
  row,
  getValue,
  updateData,
}: {
  row: Row<Control>;
  getValue: any;
  updateData: any;
}) => {
  const { accountIdRef } = useAccount();
  const [open, setOpen] = useState(false);
  const { setAlerts } = useSnackBars();
  const [canAutoRemediate, setCanAutoRemediate] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    let value = checkAutoRemediateStatus(accountIdRef);
    setCanAutoRemediate(value);
  }, [accountIdRef, row]);

  const isMultiInputControl = () => {
    const controls = Object.values(MultiInputControls).map((control) =>
      control.toString()
    );
    return (
      controls.includes(row.original.controlname) &&
      row.original.compliancestatus === "FAILED"
    );
  };

  const checkAutoRemediateStatus = (ref: string) => {
    if (row.original.remediatestatus !== "N/A") {
      let value =
        (row.original.compliancestatus === "PASSED" &&
          row.original.intrusive === "INTRUSIVE") ||
        (row.original.intrusive === "INTRUSIVE" &&
          row.original.remediatestatus === "ENABLED") ||
        row.original.intrusive === "NON-INTRUSIVE"; // Compliant and disruptive -> auto-remediation
      // Non-compliant , disruptive, and control enabled -> auto-remediation
      if (value && ref === "organisation") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  let value = getValue();

  const handleConfirm = (status: boolean) => {
    setOpen(false);
    if (status) {
      let newrRemediatestatus =
        row.original.remediatestatus === "ENABLED" ? "DISABLED" : "ENABLED";
      updateData(
        [row.original.controlname],
        "remediatestatus",
        newrRemediatestatus
      );
      setAlerts([
        {
          severity: "success",
          msg: "Continuous compliance successfully toggled.",
        },
      ]);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: "Continuous compliance toggling failed. ",
        },
      ]);
    }
  };

  const handleMultiInputConfirm = (status: boolean) => {
    setOpen(false);
    if (status) {
      setAlerts([
        {
          severity: "success",
          msg: "Multi CloudWatch input remediation is successful. Pls, check your email for SNS topic subscription confirmation.",
        },
      ]);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: "Multi CloudWatch input remediation failed.",
        },
      ]);
    }
  };

  return value === "N/A" ? (
    <>{value}</>
  ) : (
    <>
      {canAutoRemediate ? (
        isMultiInputControl() ? (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleClickOpen}
            startIcon={<HandymanIcon />}
            sx={{
              fontSize: "0.5rem",
            }}
          >
            Multi-Input
          </Button>
        ) : (
          <Switch
            checked={value === "ENABLED"}
            color="success"
            onClick={handleClickOpen}
          />
        )
      ) : (
        <Tooltip title="Remediate to enable">
          <Typography>DISABLED</Typography>
        </Tooltip>
      )}
      {!isMultiInputControl() ? (
        <AutomatedRemediationDialog
          open={open}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          row={row}
        />
      ) : (
        <MultiInputCloudwatchRemediateDialog
          open={open}
          handleClose={handleClose}
          handleConfirm={handleMultiInputConfirm}
          row={row}
        />
      )}
    </>
  );
};

const AlertSwitch = ({
  row,
  getValue,
  updateData,
}: {
  row: Row<Control>;
  getValue: any;
  updateData: any;
}) => {
  const [open, setOpen] = useState(false);
  const { setAlerts } = useSnackBars();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let value = getValue();

  const handleConfirm = (status: boolean) => {
    setOpen(false);
    if (status) {
      let newalertstatus =
        row.original.alertstatus === "ENABLED" ? "DISABLED" : "ENABLED";
      setAlerts([
        {
          severity: "success",
          msg: "Alert successfully toggled.",
        },
      ]);
      updateData([row.original.controlname], "alertstatus", newalertstatus);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: "Alert toggling failed. ",
        },
      ]);
    }
  };

  return value === "UNKNOWN" ? (
    <>{"N/A"}</>
  ) : (
    <>
      <Switch
        checked={value === "ENABLED"}
        color="success"
        onClick={handleClickOpen}
      />
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        row={row}
      ></AlertDialog>
    </>
  );
};

export { AutomateSwitch, AlertSwitch };
