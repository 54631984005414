import { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  styled,
} from "@mui/material";
import logo from "./../../assets/automate-logo-new.svg";
import ProfileSvg from "./../../assets/profile";
import LogoutSvg from "../../assets/logout";
import QuestionSvg from "../../assets/question";

import useRegions from "../../hooks/useRegions";
import useAccount from "../../hooks/useAccount";
import FadeMenu from "./fademenu";
import useCognito from "../../hooks/useCognito";
import useSnackBars from "../../hooks/useSnackbar";
import ConnectedAccount from "../../models/ConnectedAccount";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    fontWeight: 400,
    padding: "5px 21px 5px 7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.,
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const Header: FC<any> = ({ multiaccount }) => {
  const navigate = useNavigate();
  const regions = useRegions();
  const { logout } = useCognito();
  const { setAlerts } = useSnackBars();
  const location = useLocation();
  const { pathname } = location;
  const {
    arn,
    defaultregion,
    accounts,
    accountIdRef,
    connectedAccounts,
    setAccount,
    setAccountIdRef,
  } = useAccount();
  const handleChange = (e: any) => {
    const account = accounts.find((i: any) => i.arn === e.target.value);
    if (account) {
      setAccount({
        arn: account.arn,
        defaultregion: account.defaultregion,
        accounttype: account.accounttype,
        member_accounts: account.member_accounts,
        member_regions: account.member_regions,
        connectedAccounts: account.connectedAccounts,
        accountIdRef: "organisation",
      });
      setAlerts([
        {
          severity: "success",
          msg: "Changed AWS account.",
        },
      ]);
      window.location.reload();
    } else {
      setAlerts([
        {
          severity: "error",
          msg: "Somthing went wrong.",
        },
      ]);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "primary.main",
          display: "flex",
          flexDirection: "row",
          padding: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={logo} alt="6pillars's logo" />
          </Link>
        </Box>
        <FormControl
          sx={{ m: 1, minWidth: 215, backgroundColor: "primary.main" }}
          size="small"
        >
          <Select
            id="demo-simple-select"
            value={multiaccount ? arn || "" : "global"}
            onChange={handleChange}
            input={<BootstrapInput />}
            disabled={!multiaccount}
          >
            {multiaccount &&
              accounts.map((a: any) => {
                let splits = a.arn.split(":");
                return (
                  <MenuItem
                    key={a.arn}
                    value={a.arn}
                  >{`${a.accessnickname}-${splits[4]}`}</MenuItem>
                );
              })}
            {!multiaccount && (
              <MenuItem value={"global"}>{"Global - All Accounts"}</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 252 }} variant="standard">
          <BootstrapInput
            id="demo-customized-textbox"
            value={
              multiaccount
                ? regions.get(defaultregion) || "No Region"
                : "No Region"
            }
            disabled={true}
          />
        </FormControl>
        {multiaccount && (
          <FormControl
            sx={{ m: 1, minWidth: 215, backgroundColor: "primary.main" }}
            size="small"
          >
            <Select
              id="connected-account-select"
              value={
                pathname.includes("/rules") ? accountIdRef : "organisation"
              }
              onChange={(e) => {
                console.log(e.target.value);
                setAccountIdRef(e.target.value as string);
              }}
              input={<BootstrapInput />}
              disabled={
                !pathname.includes("/rules") ||
                (connectedAccounts && connectedAccounts.length < 1) ||
                (connectedAccounts &&
                  connectedAccounts.length === 1 &&
                  arn.includes(connectedAccounts[0].accountIdRef))
              }
            >
              <MenuItem value={"organisation"}>{"All Accounts"}</MenuItem>
              {connectedAccounts &&
                connectedAccounts.length > 1 &&
                connectedAccounts
                  .reduce((acc: Array<string>, a: ConnectedAccount, index) => {
                    if (a.accountIdRef && !acc.includes(a.accountIdRef)) {
                      acc.push(a.accountIdRef);
                    }
                    return acc;
                  }, [])
                  .map((accountIdRef: string) => {
                    return (
                      <MenuItem
                        key={`${accountIdRef}`}
                        value={accountIdRef}
                      >{`${accountIdRef}`}</MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        )}
        <IconButton
          aria-label="knowledge-base"
          href="//help.app.well-architected.ai"
          target="_blank"
          rel="noopener noreferrer"
          size="large"
        >
          <QuestionSvg></QuestionSvg>
        </IconButton>
        <IconButton
          aria-label="profile"
          onClick={() => {
            navigate("/profile");
          }}
          size="large"
        >
          <ProfileSvg></ProfileSvg>
        </IconButton>
        <IconButton
          aria-label="logout"
          onClick={() => {
            logout();
            navigate("/login");
          }}
          size="large"
        >
          <LogoutSvg></LogoutSvg>
        </IconButton>
      </Box>
      <Box>
        <AppBar
          position="static"
          color="default"
          sx={{ boxShadow: "none", backgroundColor: "white" }}
        >
          <Toolbar>
            <FadeMenu
              menuName="Dashboard"
              path="/"
              navigate={navigate}
              subMenuNames={[]}
            />
            <FadeMenu
              menuName="Review & Fix"
              path="/rules"
              navigate={navigate}
              subMenuNames={[]}
            />
            <FadeMenu
              menuName="AWS Automations"
              navigate={navigate}
              subMenuNames={[
                {
                  menuName: "Well-Architected",
                  path: "/wat",
                },
                {
                  menuName: "FTR Automation",
                  path: "/ftrautomation",
                },
              ]}
            />
            <FadeMenu
              menuName="Continuous Compliance"
              navigate={navigate}
              subMenuNames={[
                {
                  menuName: "Compliance Workflows",
                  path: "/multiframework",
                },
                {
                  menuName: "Remediation Queue",
                  path: "/automation-approval",
                },
                {
                  menuName: "Settings",
                  path: "/automation-approval/settings",
                },
              ]}
            />
            <FadeMenu
              menuName="IaC"
              path="/template-scanner"
              navigate={navigate}
              subMenuNames={[]}
            />
            <FadeMenu
              menuName="Integrations"
              path="/integrations"
              navigate={navigate}
              subMenuNames={[]}
            />
            <FadeMenu
              menuName="Settings"
              navigate={navigate}
              subMenuNames={[
                {
                  menuName: "Accounts",
                  path: "/accounts",
                },
                {
                  menuName: "Users",
                  path: "/profile",
                },
              ]}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
