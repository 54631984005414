import React, { useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Control from "../../../models/Control";
import { Row } from "@tanstack/react-table";
import Divider from "@mui/material/Divider";
import SixPillarsRoleButton from "../../common/SixPillarsRoleButton";
import { useInitiateRemediations } from "../../../hooks/useInitiateRemediations";
import LoadingButton from "@mui/lab/LoadingButton";
import useAccount from "../../../hooks/useAccount";

// Non-compliant and disruptive -> no auto-remediation or no one off remediation
const canAutoRemediate = (row: Row<Control>) => {
  return !(
    row.original.compliancestatus === "FAILED" &&
    row.original.intrusive === "INTRUSIVE"
  );
};

//Open Modal of MultiAutomatedRemediation
const MultiAutomatedRemediationDialog = ({
  open,
  handleClose,
  handleConfirm,
  rows,
  failedRows,
  technicalReviewArn,
  accountRefId,
}: {
  open: boolean;
  handleClose: any;
  handleConfirm: any;
  rows: Row<Control>[];
  failedRows: string[];
  technicalReviewArn: string;
  accountRefId: string;
}) => {
  const { accounts } = useAccount();
  const account = accounts.find((i: any) => i.arn === technicalReviewArn);
  const { initiateBestPracticeRemediations, status, loading, controlsStatus } = useInitiateRemediations({
    technicalReviewArn
  });

  const controls = rows
    .filter(
      (row) => row.original.remediatestatus !== "N/A" && canAutoRemediate(row)
    )
    .map((row) => ({
      controlname: row.original.controlname,
      remediationsteps: row.original.remediationsteps,
    }));

  useEffect(() => {
    if (
      controlsStatus.length > 0 &&
      !controlsStatus.some(
        (controlResult: any) => controlResult?.error !== null &&
        controlResult?.error.includes("ROLE_NOT_FOUND")
      )
    ) {    
      handleConfirm(status, controls, "");
    }
  }, [loading]);

  // Triggers initiateBestPracticeRemediations
  const handleSubmit = useCallback(async () => {
    const row = rows.filter((row) => row?.original?.compliancestatus === "FAILED")
    if (row.length > 0) {
      await initiateBestPracticeRemediations(accountRefId, failedRows);
    } else {
      handleConfirm(true, controls, "");
    }
  }, [rows, initiateBestPracticeRemediations, handleConfirm, accountRefId, failedRows, controls]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"xl"}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        Remediate all failed findings
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ m: 1 }} fontSize={"14px"}>
          <Typography variant="body1" align="left" gutterBottom>
          You are about to remediate all failed findings in the AWS account <b>{accountRefId}</b> for the selected controls.
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Control Id</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Default Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {controls.map((control) => (
                <TableRow key={control.controlname}>
                  <TableCell>{control.controlname}</TableCell>
                  <TableCell>
                    {(() => {
                      let parsedData;
                      try {
                        parsedData = JSON.parse(control.remediationsteps);
                      } catch (error) {
                        console.log(error);
                      }

                      const hasDescription =
                        parsedData?.description &&
                        typeof parsedData.description === "string" &&
                        parsedData.description.trim() !== "";

                      return (
                        <Typography fontSize={"14px"}>
                          {!hasDescription ? "N/A" : parsedData.description}
                        </Typography>
                      );
                    })()}
                  </TableCell>
                  <TableCell>
                    {(() => {
                      let parsedData;
                      try {
                        parsedData = JSON.parse(control.remediationsteps);
                      } catch (error) {
                        console.log(error);
                      }
                      const hasDefaultValues =
                        parsedData?.defaultValues &&
                        typeof parsedData.defaultValues === "object" &&
                        Object.keys(parsedData.defaultValues).length > 0;

                      return (
                        <Typography
                          fontSize={"14px"}
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {hasDefaultValues
                            ? Object.entries(parsedData.defaultValues).map(
                                ([key, value]) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <b>{`${key}`}</b>: {`${value}`}
                                      <br />
                                    </React.Fragment>
                                  );
                                }
                              )
                            : "N/A"}
                        </Typography>
                      );
                    })()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          type="submit"
          disabled={loading}
          loading={loading}
          onClick={handleSubmit}
          loadingIndicator={
            <CircularProgress color={"secondary"} size={22} />
          }
        >
          Confirm
        </LoadingButton>
      </DialogActions>
      {controlsStatus.map((controlResult: any, index) => {
        if (controlResult?.status !=="APPROVED") {
          return controlResult?.error.includes("ROLE_NOT_FOUND") ? (
              <Alert severity="error">
                <Typography sx={{ fontSize: "12px" }}>
                  {`${controlResult?.error} :
                  To update your existing role, please follow these
                  instructions.`}
                </Typography>
                {index !== controlsStatus.length - 1 && (
                  <Divider component="li" />
                )}
              </Alert>
          ) : (
            <Alert severity="error">
              {controlResult?.error}
              {index !== controlsStatus.length - 1 && (
                <Divider component="li" />
              )}
            </Alert>
          );
        }
      })}
      {controlsStatus.some((controlResult: any) => {
        if (
          controlResult?.status !=="APPROVED" &&
          controlResult?.error.includes("ROLE_NOT_FOUND")
        ) {
          return true;
        }
      }) && (
          <Alert severity="warning">
            <Typography sx={{ fontSize: "12px" }}>
              {`Step 1: Click the "Open CloudFormation" button below to open
              CloudFormation in your AWS account`}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {'Step 2: Select the "six-pillars" stack'}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {'Step 3: Click "Update"'}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {'Step 4: Select "Replace Existing Template"'}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {'Step 5: In AUTOMATE+, click on the "Copy Role Stack URL" button'}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {`Step 6: In the AWS Console, paste the Role Stack URL into the
              "Amazon S3 URL" field and click "Next" until you can select
              "Submit"`}
            </Typography>
            <SixPillarsRoleButton
              launch={false}
              update
              open
              uuid={account?.externalId}
              accountRegion={account?.defaultregion}
              template={
                  account?.accounttype === "AUTOMATED"
                  ? "roles/SixPillarRoleStack.template.json"
                  : "roles/SixPillarReadOnlyRoleStack.template.json"
              }
              />
          </Alert>
      )}
    </Dialog>
  );
};
export default MultiAutomatedRemediationDialog;
