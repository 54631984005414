import { IconButton, TableRow } from "@mui/material";
import StyledTableCell from "../../common/StyledTableCell";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteFramework from "./DeleteFramework";
import { useCallback } from "react";

interface ListMultiFrameworkItemProps {
	arn: string;
	row: {
		frameworkName: string,
		standard: string,
		awsTechnicalReviewAccount: string,
		connectedAccounts: string,
		createdAt: string
	};
	updateFramework: (
		frameworkName: string,
		standard: string,
		awsTechnicalReviewAccount: string,
		connectedAccount: string,
	) => void;
}

/**
 * Row information for list multiframeworks page
 * @param arn arn of user
 * @param row row from list multiframworks query
 * @param updateFramework calls updateFramework from Multiframework page
 * @returns row information of list multiframeworks page
 */
export default function ListMultiFrameworkItem({
	arn,
	row,
	updateFramework
}: ListMultiFrameworkItemProps){

	return (
	<TableRow>
		<StyledTableCell>{row.frameworkName}</StyledTableCell>
		<StyledTableCell>
			{new Date(row.createdAt).toLocaleString("en-GB")}
		</StyledTableCell>
		<StyledTableCell align="center">
			{row.standard}
		</StyledTableCell>
		<StyledTableCell align="center">
			{row.awsTechnicalReviewAccount.split(":")[4]}
		</StyledTableCell>
		<StyledTableCell align="center">
			{row.connectedAccounts}
		</StyledTableCell>
		<StyledTableCell align="center">
			<IconButton
				onClick={useCallback(() => {
					updateFramework(
						row.frameworkName,
						row.standard,
						row.awsTechnicalReviewAccount,
						row.connectedAccounts
					);	
				}, [updateFramework])}
				type="submit"
				color="secondary"
				>
				<AutorenewIcon />
			</IconButton>
		</StyledTableCell>
		<StyledTableCell align="center">
			<DeleteFramework
				arn={arn}
				frameworkName={row.frameworkName}
			/>
		</StyledTableCell>
	</TableRow>
	)	
}