import { Box, Button, Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useCallback, useState } from "react";
import { ListMultiFrameworkDocument, useDeleteFrameworkMutation } from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";

interface DeleteFrameworkProps {
  arn: string;
  frameworkName: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * Delete Framework from database
 * @param arn receive arn
 * @param frameworkName framework name to delete
 * @returns success or error if framework was deleted from DB
 */
export default function DeleteFramework({
  arn,
  frameworkName
}: DeleteFrameworkProps) {
	const [open, setOpen] = useState(false);
	const [disable, setDisable] = useState(false);
	const { setAlerts } = useSnackBars();

  //Handle Open
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  //Handle Close
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

	const [deleteFrameworkMutation] = useDeleteFrameworkMutation({
		variables: {
			arn, // value for 'arn'
			frameworkName,// value for 'frameworkName'
		},
		context: {
      apiName: "well_architected"
    },
		notifyOnNetworkStatusChange: true,
		onCompleted: () => {
      handleClose();
      setDisable(false);
    },
		refetchQueries: [{
      query: ListMultiFrameworkDocument,
      context: {
        apiName: "well_architected"
      },
      variables: {
        arn,
      },
    }]
	});

  //Calls deleteFramework API
	const handleDelete = useCallback(() => {
		setDisable(true);
		deleteFrameworkMutation().then(() => {
      setAlerts([
        {
          severity: "success",
          msg: "Workload successfully deleted",
        },
      ]);
    })
    .catch((e) => {
      setAlerts([
        {
          severity: "error",
          msg: `Workload deletion failed - ${e}`,
        },
      ]);
    });
	}, [setDisable, deleteFrameworkMutation, setAlerts]);

  return (
    <Stack direction="row">
      <IconButton onClick={handleOpen} color="secondary"><DeleteOutlineIcon /></IconButton>
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs md={10}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Framework
              </Typography>
            </Grid>
            <Grid item xs md={2}>                            
              <Button onClick={handleClose}><CloseIcon /></Button>
            </Grid>
            <Grid item xs md={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {frameworkName}?
              </Typography>
            </Grid>
            <Grid item xs md={4} sx={{ mt: 2}} />
            <Grid item xs md={8} sx={{ mt: 2}}>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
              >
                <Button 
                  disabled={disable} 
                  onClick={handleDelete} 
                  type="submit" 
                  variant="outlined" 
                  sx={{
                      width: 157,
                      height: 40,
                      "&:hover": {
                          color: "primary.main"
                      }
                  }}
                >
                  Yes
                </Button>
                <Button 
                  disabled={disable} 
                  onClick={handleClose} 
                  type="submit" 
                  variant="contained"
                  sx={{
                      width:  157,
                      height: 40,
                      bgcolor: "secondary.main",
                      "&:hover": {
                          bgcolor:"secondary.main",
                          color: "secondary.contrastText"
                      }
                  }}
                >
                  No
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>                
    </Stack>
  );
}