import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import Heading from "../../common/Heading";
import { useState, memo, useCallback } from "react";
import useAccount from "../../../hooks/useAccount";
import { useGetCognitoAccountQuery } from "../../../generated";
import MultiFrameworkSelection from "./MultiFrameworkSelection";
import ConnectedAccount from "../../../models/ConnectedAccount";
import NonDisruptiveRecommendations from "./NonDisruptiveRecommendations";
import FrameworkQuestionnaire from "./FrameworkQuestionnaire";
import GenerateReports from "./GenerateReports";
import TechnicalControlsList from "./TechnicalControlsList";
import ListMultiFrameworks from "./ListMultiFrameworks";

type AnswerQuestion = {
  id: string;
  description: string;
  selection: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = memo(({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </Container>
  );
});

/**
 * Used for tabs
 */
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * Main Page of MultiFramework
 */
export default function MultiFramework() {
  const [value, setValue] = useState(0);
  const { arn, accounts, accounttype } = useAccount();
  const [frameworkName, setFrameworkName] = useState("");
  const [targetAccountType, setTargetAccountType] = useState(accounttype);
  const [technicalReviewArn, setTechnicalReviewArn] = useState("");
  const [frameworkSelection, setFrameworkSelection] = useState("");
  const [technicalControlsData, setTechnicalControlsData] = useState([]);
  const [connectedAccounts, setConnectedAccounts] = useState<Array<ConnectedAccount>>([]);
  const [answers, setAnswers] = useState<Array<AnswerQuestion>>([]);
  const [selectedControls, setSelectedControls] = useState<any[]>([]);

  // Function to update selected controls
  const updateSelectedControls = useCallback((control: any, isSelected: boolean) => {
    setSelectedControls((prev) =>
      isSelected
        ? prev.some((c) => c.controlname === control.controlname)
          ? prev
          : [...prev, control]
        : prev.filter((c) => c.controlname !== control.controlname)
    );
  }, [setSelectedControls]);

  const [unlockSteps, setUnlockSteps] = useState({
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  });

  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  // Handle answer changes
  const handleAnswerChange = useCallback((questionId: string, question: string, newAnswer: string) => {
    setAnswers((prev) => {
      const index = prev.findIndex((ans) => ans.id === questionId);
      if (index !== -1) {
        // Update existing answer
        const updatedAnswers = [...prev];
        updatedAnswers[index].selection = newAnswer;
        return updatedAnswers;
      } else {
        // Add new answer
        return [...prev, { id: questionId, description: question, selection: newAnswer }];
      }
    });
  }, [setAnswers]);

  // Handles which tab to change to
  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, [setValue]);

  // Gets triggered when list frameworks tab is loaded
  const resetStates = useCallback(() => {
    setFrameworkName("");
    setTechnicalReviewArn("");
    setFrameworkSelection("");
    setTargetAccountType("");
    setConnectedAccounts([]);  
  }, [
    setFrameworkName,
    setTechnicalReviewArn,
    setFrameworkSelection,
    setTargetAccountType,
    setConnectedAccounts
  ]);

  // Gets triggered when update button is clicked from list frameworks page
  const updateFramework = useCallback((
    frameworkName: string,
		standard: string,
		awsTechnicalReviewAccount: string,
    accountIdRef: string,
  ) => {
    setFrameworkName(frameworkName);
    setTechnicalReviewArn(awsTechnicalReviewAccount);
    setFrameworkSelection(standard);

    const account = accounts.find((i: any) => i.arn === awsTechnicalReviewAccount);

    if(account) {
      if(account?.connectedAccounts.length > 1){
        if(account?.member_accounts?.includes(accountIdRef)){
          setTargetAccountType("AUTOMATED");  
        } else {
          setTargetAccountType("READ_ONLY");
        }
        //Get filtered connected accounts
        const getFilteredConnectedAccounts = () => {
          return (
            account?.connectedAccounts
              ?.filter((connectedAccount: ConnectedAccount) => {
                return connectedAccount.accountIdRef === accountIdRef;
              })
              .map((connectedAccount: ConnectedAccount) => {
                return {
                  accountIdRef: connectedAccount.accountIdRef,
                  region: connectedAccount.region,
                };
              }) || []
          );
        };
        setConnectedAccounts(getFilteredConnectedAccounts());  
      } else {
        setTargetAccountType(account?.accounttype);
        setConnectedAccounts([]);  
      } 
    }
    //Swap to step 2
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: true, // Unlock step 2
    }));
    setValue(1);
  }, [
    setFrameworkName,
    setTechnicalReviewArn,
    setFrameworkSelection,
    accounts,
    setTargetAccountType,
    setConnectedAccounts,
    setUnlockSteps,
    setValue
  ]);
  
  const tabPanels = [
    {
      index: 0,
      component: (
        <MultiFrameworkSelection
          arn={arn}
          organisation={getUserdata?.getCognitoAccount?.organisation}
          targetAccountType={targetAccountType}
          setUnlockSteps={setUnlockSteps}
          changeTab={useCallback((index: number, frameworkName: string, targetArn: string, frameworkSelection: string, connectedAccounts: Array<ConnectedAccount>, accounttype: string) => {
            setValue(index);
            setFrameworkName(frameworkName);
            setTechnicalReviewArn(targetArn);
            setFrameworkSelection(frameworkSelection);
            setConnectedAccounts(connectedAccounts);
            setTargetAccountType(accounttype);
          },[
            setValue,
            setFrameworkName,
            setTechnicalReviewArn,
            setFrameworkSelection,
            setConnectedAccounts,
            setTargetAccountType
          ])}
        />
      ),
    },
    {
      index: 1,
      component: (
        <TechnicalControlsList
          frameworkSelection={frameworkSelection}
          technicalReviewArn={technicalReviewArn}
          connectedAccounts={connectedAccounts}
          setTechnicalControlsData={setTechnicalControlsData}
          setUnlockSteps={setUnlockSteps}
          changeTab={useCallback((index: number) => setValue(index),[setValue])}
        />
      ),
    },
    {
      index: 2,
      component: (
        <NonDisruptiveRecommendations
          targetAccountType={targetAccountType}
          changeTab={useCallback((index: number) => setValue(index),[setValue])}
          setUnlockSteps={setUnlockSteps}
          frameworkSelection={frameworkSelection}
          technicalReviewArn={technicalReviewArn}
          connectedAccounts={connectedAccounts}
          selectedControls={selectedControls} // Pass the centralized state
          updateSelectedControls={updateSelectedControls} // Pass the updater function
        />
      ),
    },
    {
      index: 3,
      component: (
        <FrameworkQuestionnaire
          arn={arn}
          frameworkName={frameworkName}
          frameworkSelection={frameworkSelection}
          setUnlockSteps={setUnlockSteps}
          answers={answers}
          onAnswerChange={handleAnswerChange}
          changeTab={useCallback((index: number) => setValue(index),[setValue])}
        />
      ),
    },
    {
      index: 4,
      component: (
        <GenerateReports
          arn={arn}
          frameworkSelection={frameworkSelection}
          technicalControlsData={technicalControlsData}
          answers={answers}
          connectedAccounts={connectedAccounts}
          changeTab={useCallback((index: number) => setValue(index),[setValue])}
        />
      ),
    },
    {
      index: 5,
      component: (
        <ListMultiFrameworks
          arn={arn}
          updateFramework={updateFramework}
          resetStates={resetStates}
          setUnlockSteps={setUnlockSteps}
        />
      ),
    },
    // Add placeholders for other panels if needed
  ];

  return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading="Automated Multi-Framework Review" />
      </Container>
      <Box sx={{ width: "100%", pt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
          >
            <Tab label="STEP 1: Select Framework" {...a11yProps(0)} />
            <Tab
              label="STEP 2: Technical Controls"
              {...a11yProps(1)}
              disabled={!unlockSteps.step2}
            />
            <Tab
              label="STEP 3: Non-Disruptive Recommendations"
              {...a11yProps(2)}
              disabled={!unlockSteps.step3}
            />
            <Tab
              label="STEP 4: Policies and Process Review"
              {...a11yProps(3)}
              disabled={!unlockSteps.step4}
            />
            <Tab
              label="STEP 5: Generate Auditor Reports"
              {...a11yProps(4)}
              disabled={!unlockSteps.step5}
            />
            <Tab
              label="Review Frameworks"
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        {tabPanels.map(({ index, component }) => (
          <TabPanel value={value} index={index} key={`MultiFramework-${index}`}>
            {component}
          </TabPanel>
        ))}
      </Box>
    </Container>
  );
}
